import React, { useEffect, useState } from 'react';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import RenderMobileInactive from './render-mobile-inactive';
import { PREVIEW_TYPES } from '../../consts/images.const';
import SpotBreadcrumbs from '../../components/spot-breadcrumbs';
import RenderHostInfo from './render-host-info';
import RenderInactive from './render-inactive';
import SniffCarouselClient from '../../components/carousel';
import RequestMorePhotos from '../../components/request-more-photos';
import SniffCarouselSSR from '../../components/carousel/carousel-ssr';
import RenderSubInfo from './render-sub-info';
import ListingHead from '../../components/listing-head';
import SniffButton from '../../components/sniff-button';
import { AUTH_TESTS } from './auth-test.const';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { RouteFormatter } from '../../routes';
import { isUnverified } from '../../consts/user.consts';
import { SIGN_UP_SRC } from '../../consts/profile.const';
import RenderHappyDogGuarantee from './render-happy-dog-guarantee';

const CookieService = require('../../services/cookie.service');

const RenderCarouselServer = ({ spot, toggleMobileGallery }) => (
    <SniffCarouselSSR
        {...{
            item: spot,
            useEffect,
            useState,
            lazy: 'eager',
            showArrow: false,
            server: true,
        }}
        onClick={() => toggleMobileGallery(true)}
        spotGallery
    />
);

const RenderCarouselClient = ({ spot, handleSwipeMobile, mobilePhotoCount, toggleMobileGallery }) => (
    <SniffCarouselClient
        images={spot.spotPhotos}
        type={PREVIEW_TYPES.VIEW}
        disableArrow
        handleSwipeMobile={handleSwipeMobile}
        mobilePhotoCount={mobilePhotoCount}
        onClick={() => toggleMobileGallery(true)}
        spotHost={spot.host}
        spotId={spot.id}
        title={spot.title}
        state={spot.state}
        city={spot.city}
        lazy="eager"
        spotGallery
    />
);

const RenderHeadMobile = ({
    spot,
    showDisTop,
    setMobilePhotoGallery,
    handleSwipeMobile,
    mobilePhotoCount,
    listBread,
    goToReviews,
    goToHost,
    handleBook,
    handleSniffpassBadge,
    handleMembershipBadge,
    userDetails,
    isHappyDogTest,
    mutateViewHappyDogTest,
    isMobile,
}) => {
    const [Carousel, setCarousel] = useState(() => RenderCarouselServer);

    const toggleMobileGallery = async (bool = false) => {
        const isUnver = isUnverified(userDetails);

        if (!userDetails?.id || isUnver) {
            const re = `${window.location.pathname}#${AUTH_TESTS.MOBILE_PHOTO_GALLERY}`;

            if (isUnver) {
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, window.location.href);
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT_ONBOARD, re);
                window.location = RouteFormatter.finishYourAccount();
                return;
            }

            CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
            CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
            window.location = RouteFormatter.signUp();
            return;
        }

        setMobilePhotoGallery(bool);
    };

    const CarouselComp = <Carousel {...{ spot, handleSwipeMobile, mobilePhotoCount, toggleMobileGallery }} />;

    useEffect(() => {
        setCarousel(() => RenderCarouselClient);
    }, []);

    return (
        <div className="head">
            <div className="d-block w-100">
                {(!spot.live || spot.host?.id == 0) && (
                    <>
                        {showDisTop && <RenderMobileInactive spot={spot} />}
                        <RenderInactive {...{ spot, useState }} />
                    </>
                )}
                <div className="mobile-carousel">
                    {spot.spotPhotos && spot.spotPhotos.length > 0 ? (
                        <div className={`spot-main-photo ${useState ? 'carou-ssr' : ''}`}>{CarouselComp}</div>
                    ) : (
                        <div className="spot-main-photo placeholder-photo">
                            <RequestMorePhotos id={spot.id} />
                        </div>
                    )}
                </div>
                <SpotBreadcrumbs SSR {...{ listBread }} />
                <div className="info w-100">
                    <ListingHead
                        size={spot.size}
                        fncd={spot.enclosureType}
                        title={spot.title}
                        city={spot.city}
                        state={spot.state}
                        sizeUnits={spot.sizeUnits}
                        distanceFrom={spot.distanceFrom}
                        userDetails={userDetails}
                    />
                </div>
                <RenderSubInfo
                    spot={spot}
                    handleGoTo={goToReviews}
                    handleSniffpassBadge={handleSniffpassBadge}
                    handleMembershipBadge={handleMembershipBadge}
                />
                <RenderHostInfo spot={spot} handleGoTo={goToHost} useState={useState} />
                <div className="container mt-2">
                    <SniffButton
                        className="mb-2"
                        color="secondary"
                        size="lg"
                        disabled={!spot.live || spot.host?.id == 0}
                        onClick={() => handleBook(RESERVATION_TYPE.CALENDAR)}
                    >
                        Find an available time
                    </SniffButton>
                    {isMobile && <RenderHappyDogGuarantee isHappyDogTest={isHappyDogTest} mutateViewHappyDogTest={mutateViewHappyDogTest} />}
                    <hr />
                </div>
            </div>
        </div>
    );
};

export default RenderHeadMobile;
