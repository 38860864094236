import React from 'react';
import SvgCommentQuote from 'src/assets/svg/SvgCommentQuote.svg';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgPin from 'src/assets/svg/SvgPin.svg';
import SvgRuler from 'src/assets/svg/SvgRuler.svg';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import { SPOT_ENCLOSURE } from '../../consts/spot.consts';
import { showSize } from '../../helpers/common';
import { showPrice } from '../../helpers/price';
import { showDistanceFromYou } from '../../helpers/spot';
import { getInitState } from '../../helpers/spot-slug';
import SpotAmenitiesIcon from '../spot-amenities-icon';
import './expanded-spot-detail.scss';

const ExpandedSpotDetail = ({ item = {}, isCityTest, userDetails }) => {
    const { state, city, distanceFrom, country, publicPark } = item;
    const initState = state ? getInitState(state) : '';
    const fixedRating = Math.round(item.rating * 10) / 10;
    const locationText =
        state || city ? `${city ? `${city},` : ''}${state ? ` ${initState || state}${showDistanceFromYou(distanceFrom, userDetails)}` : ''}` : '';

    return (
        <div className="item-full-expand">
            {isCityTest ? (
                <div className="d-flex justify-content-between align-items-center snif-s1 mt-1">
                    <p className={`spot-type-public ${publicPark ? 'public-spot' : ''}`}>{publicPark ? 'Public dog park' : 'Private dog park'}</p>
                    {item.reviewsCount > 0 && (
                        <p className="d-flex align-items-center snif-s1">
                            <SvgStarSolid width={16} height={16} />
                            <span className="snif-medium ms-05">{fixedRating}</span>
                            <span className="text-medium ms-05">({item.reviewsCount})</span>
                        </p>
                    )}
                </div>
            ) : (
                <p className="d-flex align-items-center snif-s1 mt-1">
                    {item.reviewsCount > 0 && (
                        <>
                            <SvgStarSolid width={16} height={16} />
                            <span className="snif-medium ms-05">{fixedRating}</span>
                            <span className="text-medium ms-05">({item.reviewsCount})</span>
                        </>
                    )}
                </p>
            )}
            <div className="item-full-expand_title">
                <h4 className="snif-m2 snif-semibold">{item.title}</h4>
                {item.offerSniffpass && <SvgSubscription />}
            </div>
            {(!!item.enclosureType || !!item.size) && (
                <div className="item-full-expand_enclosure">
                    {!!item.enclosureType && (
                        <div className="d-flex align-items-center">
                            <SvgFenced />
                            <span className="snif-s1 snif-regular">{SPOT_ENCLOSURE[item.enclosureType.toUpperCase()]}</span>
                        </div>
                    )}
                    {!!item.enclosureType && !!item.size && <div className="item-full-expand_enclosure_dot" />}
                    {!!item.size && (
                        <div className="d-flex align-items-center">
                            <SvgRuler />
                            <span className="snif-s1 snif-regular">{showSize(item.size, item.sizeUnits)}</span>
                        </div>
                    )}
                </div>
            )}
            {item.description && <p className="item-full-expand_desc">{item.description}</p>}
            {item.workHours && <p className="item-full-expand_desc">Park hours: {item.workHours}</p>}
            {item.allAmenities?.length > 0 && (
                <div className="item-full-expand_amenities">
                    {item.allAmenities.map((amenity) => (
                        <SpotAmenitiesIcon key={amenity.component} amenities={amenity} />
                    ))}
                </div>
            )}
            {item.topReview?.id && (
                <div className="item-full-expand_review">
                    <div className="item-full-expand_review_svg">
                        <SvgCommentQuote />
                    </div>
                    <p>
                        {`“${item.topReview.comment}” `}
                        <span>more</span>
                    </p>
                </div>
            )}
            <div className="item-detail-price">
                {item.fee == false ? (
                    <div className="item-detail-price-left">
                        <span className="snif-s1 text-deep">No fee info</span>
                    </div>
                ) : item.price || item.fee ? (
                    <div className="item-detail-price-left">
                        {item.fee ? (
                            <span className="snif-s1 text-deep">{item.fee}</span>
                        ) : (
                            <>
                                <span className="snif-p snif-semibold">{showPrice(item.price, 0, country !== 'United States')}</span>
                                <span className="snif-s1 text-deep"> dog / hour</span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="item-detail-price-left" />
                )}
                {locationText && (
                    <div className="item-detail-price-right">
                        <div className="d-flex align-items-center">
                            <SvgPin width={16} height={16} />
                        </div>
                        <span className="snif-s1 snif-regular ms-1">{locationText}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpandedSpotDetail;
