import React, { useEffect, useRef, useState } from 'react';
import bemCn from 'bem-cn';
import isbot from 'isbot';
import { useStatsigClient } from '@statsig/react-bindings';
import {
    LOW_CLEAN_THRESHOLD,
    LOW_FENCING_THRESHOLD,
    LOW_PHOTOS_THRESHOLD,
    LOW_RATING_THRESHOLD,
    RESERVATION_TYPE,
} from '../../consts/reservation.consts';
import loadable from '@loadable/component';
import defImgSpot from '../../assets/img/host-2.png';
import { showPrice } from '../../helpers/price';
import { AUTH_TESTS } from './auth-test.const';
import { EVENTS_NAME } from '../../consts/event.consts';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import DesktopBookBlock from './desktop-book-block';
import { getTopBarProps } from './helper';
import { RouteFormatter } from '../../routes';
import { sortByObjTitle } from '../../helpers/reservation';
import { scrollToElement } from '../../helpers/common';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { SIGN_UP_SRC, USER_TEST_GROUP } from '../../consts/profile.const';
import { getRandomCookieTestSSR, getTestWithProbSSR } from '../../helpers/ssr';
import { ENCLOSURE_TYPE, SPOT_MIN_REQ_PHOTOS, SPOT_REVIEWS_SORT_CONTENT, SPOT_SRC, SPOT_SRC_MEMBERSHIP } from '../../consts/spot.consts';
import ListingAvailability from '../../components/listing-availability';
import GuestSpotCalendar from '../../components/calendar/guest-calendar-ssr';
import SeoLocalBusiness from '../../components/seo-local-business';
import ReservationPanel from '../../components/reservation-panel';
import ListingAmenities from '../../components/listing-amenities';
import ListingLocation from '../../components/listing-location/index-ssr';
import ListingPresent from '../../components/listing-present';
import ListingReviews from '../../components/listing-reviews';
import ListingFencing from '../../components/listing-fencing';
import ListingExtras from '../../components/listing-extras';
import MobileSubPage from '../../components/mobile-sub-page';
import ListingRules from '../../components/listing-rules';
import ListingHost from '../../components/listing-host';
import ModalMobile from '../../components/modal-mobile';
import SniffButton from '../../components/sniff-button';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './index.scss';

import RenderMobilePhotoGallery from './render-mobile-photo-gallery';
import RenderHappyDogGuarantee from './render-happy-dog-guarantee';
import RenderUserIsMember from './render-user-is-member';
import RenderModalWarning from './render-modal-warning';
import RenderNearbySpots from './render-nearby-spots-ssr';
import RenderHeadDesktop from './render-head-desktop';
import RenderHeadMobile from './render-head-mobile';
import RenderSpotBadge from './render-spot-badge';
import RenderSpotNext from './render-spot-next';
import RenderPrice from './render-price';

const b = bemCn('memberships-modal-spot-details');
const bs = bemCn('sniffpass-modal-spot-details');
const bSignUp = bemCn('host-landing');

const RenderSignUpEmail = loadable(() => import('../host-landing/render-sign-up-email'));

const CookieService = require('../../services/cookie.service');
const useMutateTrackEvent = require('../../services/hooks/useMutateTrackEvent');
const useGetSpotTopBarData = require('../../services/hooks/useGetSpotTopBarData');
const useGetProbabilityTest = require('../../services/hooks/useGetProbabilityTest');
const useMutateViewAuthSpot = require('../../services/hooks/useMutateViewAuthSpot');
const useMutateViewSpotTopBar = require('../../services/hooks/useMutateViewSpotTopBar');
const useMutateViewHappyDogTest = require('../../services/hooks/useMutateViewHappyDogTest');
const useMutateViewSpotDetailScroll = require('../../services/hooks/useMutateViewSpotDetailScroll');

const favSpots = [];

const SpotViewPage = ({
    spot,
    userDetails = {},
    useCallback,
    usePrevious,
    useGetAvailableTimes,
    useLazyGetIntentCal,
    useUpdateIntent,
    useMutateFavorites,
    useGetShareLink,
    useAddReply,
    useDeleteReply,
    useHandleUpvote,
    useGetSpotReviews,
    useOnScreen,
    useGetReviews,
    Select,
    serverSSR,
    statsigLoading,
}) => {
    const isMobile = useMobileScreen();
    const hostRef = useRef(null);
    const reviewsRef = useRef(null);
    const { getReviews, dataReviews } = serverSSR ? {} : useGetReviews();
    const { trackEvent } = serverSSR ? {} : useMutateTrackEvent.default();
    const { getProbTest } = serverSSR ? {} : useGetProbabilityTest.default();
    const { getSpotTopBarData } = serverSSR ? {} : useGetSpotTopBarData.default();
    const { mutateViewAuthSpot } = serverSSR ? {} : useMutateViewAuthSpot.default();
    const { mutateViewSpotTopBar } = serverSSR ? {} : useMutateViewSpotTopBar.default();
    const { mutateViewHappyDogTest } = serverSSR ? {} : useMutateViewHappyDogTest.default();
    const { mutateViewSpotDetailScroll } = serverSSR ? {} : useMutateViewSpotDetailScroll.default();
    const [sortBy, setSortBy] = useState('COMMENT');
    const [canPrev, setCanPrev] = useState(false);
    const [memberSrc, setMemberSrc] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isSpotTest, setIsSpotTest] = useState(null);
    const [topBarData, setTopBarData] = useState(null);
    const [sortReviews, setSortReviews] = useState(SPOT_REVIEWS_SORT_CONTENT.COMMENT);
    const [showCalendar, setShowCalendar] = useState((typeof window !== 'undefined' && userDetails?.id && window.location.hash?.substring(1)) || '');
    const [openSniffpass, setOpenSniffpass] = useState(false);
    const [isTopBarSignup, setIsTopBarSignup] = useState(false);
    const [openMembership, setOpenMembership] = useState(false);
    const [singleViewPhoto, setSingleViewPhoto] = useState(null);
    const [openScrollModal, setOpenScrollModal] = useState(false);
    const [mobilePhotoCount, setMobilePhotoCount] = useState(0);
    const [desktopPhotoCount, setDesktopPhotoCount] = useState(0);
    const [isGuestScrollTest, setIsGuestScrollTest] = useState(null);
    const [mobilePhotoGallery, setMobilePhotoGallery] = useState(false);
    const [desktopPhotoGallery, setDesktopPhotoGallery] = useState(false);
    const [warning, setWarning] = useState({
        isOpen: false,
        isLowRating: false,
        isLongInactive: false,
        type: null,
    });
    const isCanReqPhotos = spot.spotPhotos?.length < SPOT_MIN_REQ_PHOTOS;
    const { checkGate } = useStatsigClient();
    const isHappyDogTest = statsigLoading ? null : checkGate(USER_TEST_GROUP.GUEST_HAPPY_DOG_TEST.toLowerCase());

    let y = serverSSR ? 0 : window.scrollY;

    const listBread = [
        {
            name: 'All dog parks',
            url: RouteFormatter.listings({}),
        },
        {
            name: spot?.state || '',
            url: RouteFormatter.listings({ first: spot?.stateUrl }) || '',
        },
        {
            name: spot?.city || '',
            url: spot?.cityUrl || '',
        },
        {
            name: spot?.title || '',
        },
    ];

    useEffect(() => {
        const htmlEle = document.documentElement;

        if (mobilePhotoGallery) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            htmlEle.classList.add('root-html-focused');
        } else {
            setMobilePhotoCount(0);
            htmlEle.classList.remove('root-html-focused');
        }

        return () => htmlEle.classList.remove('root-html-focused');
    }, [mobilePhotoGallery]);

    useEffect(() => {
        const isBotCrawl = isbot(navigator.userAgent);
        trackEvent({ name: EVENTS_NAME.spot_detail_view, properties: { spot_id: spot.id } });
        const isTopBarTest = CookieService.default.get(COOKIE_PARAM_NAME.SNIFF_TOP_BAR_SIGNUP_5019) == 'NEW';
        setIsTopBarSignup(isTopBarTest);

        const init = async () => {
            const isSpotTest = await getTestWithProbSSR({
                getProbTest,
                cookie: COOKIE_PARAM_NAME.SPOT_DETAIL_4686_TEST,
                userTestGroup: USER_TEST_GROUP.SPOT_DETAIL_TOP_BAR,
            });

            const isScrollTest = await getTestWithProbSSR({
                getProbTest,
                cookie: COOKIE_PARAM_NAME.GUEST_SPOT_DETAIL_SCROLL,
                userTestGroup: USER_TEST_GROUP.GUEST_SPOT_DETAIL_SCROLL,
            });

            if (isScrollTest != null) {
                mutateViewSpotDetailScroll({
                    variables: { testGroup: isScrollTest, sessionUuid: CookieService.default.get(COOKIE_PARAM_NAME.SESSION_UUID) },
                });
                setIsGuestScrollTest(isScrollTest);
            }

            if (isSpotTest != null) {
                mutateViewSpotTopBar({ variables: { testGroup: isSpotTest } });
                setIsSpotTest(isSpotTest);
            }
            if (isSpotTest) {
                const spotId = spot.id;

                // get data for top bar test
                const splittedReff = window.document.referrer.split('-');

                if (splittedReff.length <= 1) {
                    const center = { lat: parseFloat(spot.latitude), lng: parseFloat(spot.longitude) };
                    const strCenter = JSON.stringify(center);
                    const topBarProps = getTopBarProps(center);
                    const res = await getSpotTopBarData({ variables: { id: spotId, ...topBarProps } });
                    setTopBarData(res.data?.spot);
                    CookieService.default.set(COOKIE_PARAM_NAME.FIRST_SPOT_4686, `${spotId}-S-${strCenter}`);
                    setCanPrev(false);
                    return;
                }

                const spotCookie = CookieService.default.get(COOKIE_PARAM_NAME.FIRST_SPOT_4686);
                const spotIdCookie = spotCookie?.split('-S-')[0];

                if (spotCookie && spotId == parseInt(spotId) && spotId != spotIdCookie) {
                    const spotLatLng = spotCookie?.split('-S-')[1];
                    const center = JSON.parse(spotLatLng);
                    const topBarProps = getTopBarProps(center);
                    const res = await getSpotTopBarData({ variables: { id: spotId, ...topBarProps } });
                    setTopBarData(res.data?.spot);
                    setCanPrev(true);
                } else {
                    const center = { lat: parseFloat(spot.latitude), lng: parseFloat(spot.longitude) };
                    const strCenter = JSON.stringify(center);
                    const topBarProps = getTopBarProps(center);
                    const res = await getSpotTopBarData({ variables: { id: spotId, ...topBarProps } });
                    setTopBarData(res.data?.spot);
                    CookieService.default.set(COOKIE_PARAM_NAME.FIRST_SPOT_4686, `${spotId}-S-${strCenter}`);
                    setCanPrev(false);
                }
            }
        };

        !isBotCrawl && init();

        return () => {
            setSingleViewPhoto(null);
            setMobilePhotoCount(0);
            setDesktopPhotoCount(0);
            setMobilePhotoGallery(false);
            setDesktopPhotoGallery(false);
        };
    }, []);

    // guest auth test
    useEffect(() => {
        if (mobilePhotoGallery || desktopPhotoGallery) {
            const re = `${window.location.pathname}#${mobilePhotoGallery ? AUTH_TESTS.MOBILE_PHOTO_GALLERY : AUTH_TESTS.DESKTOP_PHOTO_GALLERY}`;
            CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
            CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
            setSingleViewPhoto(null);
            setMobilePhotoCount(0);
            setDesktopPhotoCount(0);
            setMobilePhotoGallery(false);
            setDesktopPhotoGallery(false);
            window.location = RouteFormatter.signUp();
        }
    }, [mobilePhotoGallery, desktopPhotoGallery]);

    const changeSort = (key, force) => {
        setSortBy(key);
        getReviews({
            variables: {
                id: spot.id,
                sortBy: SPOT_REVIEWS_SORT_CONTENT[key].sortBy,
                sortOrder: SPOT_REVIEWS_SORT_CONTENT[key].sortOrder,
                first: 10,
            },
            fetchPolicy: force ? 'network-only' : 'cache-and-network',
        });
        setSortReviews(SPOT_REVIEWS_SORT_CONTENT[key]);
    };

    const onAskQuestion = ({ preText }) => {
        const spotId = spot.id;
        CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.MESSAGE_HOST_WEB, { expires: 1 });

        if (preText) {
            window.location = RouteFormatter.guestConversation({ channelId: spotId }, { query: { preText } });
        } else {
            window.location = RouteFormatter.guestConversation({ channelId: spotId });
        }
    };

    const singlePhotoHandler = (id) => {
        setMobilePhotoCount(id + 1);
        setSingleViewPhoto(id);
    };

    const toggleDesktopPhotoGallery = (idx = 0) => {
        setDesktopPhotoGallery(!desktopPhotoGallery);
        setDesktopPhotoCount(idx);
    };

    const handleNextDesktopGallery = () => {
        let newIdxPhoto = desktopPhotoCount;

        if ((isCanReqPhotos && newIdxPhoto == spot.spotPhotos?.length) || (!isCanReqPhotos && newIdxPhoto + 1 == spot.spotPhotos?.length)) {
            newIdxPhoto = 0;
        } else {
            newIdxPhoto++;
        }

        setDesktopPhotoCount(newIdxPhoto);
    };

    const handleBackDesktopGallery = () => {
        let newIdxPhoto = desktopPhotoCount;

        if (newIdxPhoto - 1 < 0) {
            newIdxPhoto = isCanReqPhotos ? spot.spotPhotos?.length : spot.spotPhotos.length - 1;
        } else {
            newIdxPhoto--;
        }

        setDesktopPhotoCount(newIdxPhoto);
    };

    const handleSwipeMobile = () => {
        const re = `${window.location.pathname}#${AUTH_TESTS.MOBILE_PHOTO_GALLERY}`;
        CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
        CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
        setSingleViewPhoto(null);
        setMobilePhotoCount(0);
        setDesktopPhotoCount(0);
        setMobilePhotoGallery(false);
        setDesktopPhotoGallery(false);
        window.location = RouteFormatter.signUp();
    };

    const toggleOpenMembership = () => setOpenMembership(!openMembership);
    const toggleOpenSniffpass = () => setOpenSniffpass(!openSniffpass);

    const handleMembershipBadge = () => {
        if (spot.isMember) {
            window.location = RouteFormatter.subscriptions();
            return;
        }
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_DETAIL_BADGE);
        toggleOpenMembership();
    };

    const handleSniffpassBadge = () => {
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_DETAIL_BADGE);
        toggleOpenSniffpass();
    };

    const handleMembershipReviewBadge = (e) => {
        e.stopPropagation();
        if (spot.isMember) {
            window.location = RouteFormatter.subscriptions();
            return;
        }
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_REVIEW_BADGE);
        toggleOpenMembership();
    };

    const handleBook = async (type) => {
        setMobilePhotoGallery(false);
        setDesktopPhotoGallery(false);

        if (!userDetails?.id) {
            const addedSrc = type == RESERVATION_TYPE.BOOK ? SPOT_SRC.WEB_SPOT : SPOT_SRC.WEB_CALENDAR;
            const signSrc = type == RESERVATION_TYPE.BOOK ? SIGN_UP_SRC.CHECKOUT_WEB : SIGN_UP_SRC.CALENDAR_WEB;
            const redirectUrl = `${window.location.href}#${addedSrc}`;
            CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, redirectUrl, { expires: 1 });
            CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, signSrc, { expires: 1 });
            getRandomCookieTestSSR(COOKIE_PARAM_NAME.ONE_PAGE_CHECKOUT_TEST);
            window.location = RouteFormatter.signUp();
            return;
        }

        const isLowRating = spot.rating !== null ? spot.rating < LOW_RATING_THRESHOLD : false;
        const isLongInactive = spot.inactiveWarning;
        const isFencingIssue =
            spot.enclosureType === ENCLOSURE_TYPE.FULLY_FENCED && spot.fencingPercentage !== null && spot.fencingPercentage < LOW_FENCING_THRESHOLD;
        const isCleanIssue = spot.cleanlinessPercentage && spot.cleanlinessPercentage < LOW_CLEAN_THRESHOLD;
        const isPhotosIssue = spot.spotPhotos?.length < LOW_PHOTOS_THRESHOLD;

        if (isLowRating || isLongInactive || isFencingIssue || isCleanIssue || isPhotosIssue) {
            if (!userDetails.id || (userDetails.id && !spot?.hasBookedBefore)) {
                setWarning({
                    isOpen: true,
                    isLowRating,
                    isLongInactive,
                    isFencingIssue,
                    isCleanIssue,
                    isPhotosIssue,
                    type,
                    SSR: true,
                });
                return;
            }
        }

        switch (type) {
            case RESERVATION_TYPE.BOOK:
                window.location.hash = SPOT_SRC.WEB_SPOT;
                setShowCalendar(SPOT_SRC.WEB_SPOT);
                break;
            case RESERVATION_TYPE.CALENDAR:
                window.location.hash = SPOT_SRC.WEB_CALENDAR;
                setShowCalendar(SPOT_SRC.WEB_CALENDAR);
                break;
            default:
                break;
        }
    };

    const goToReviews = () => reviewsRef.current && scrollToElement(reviewsRef.current, isMobile);
    const goToHost = () => hostRef.current && scrollToElement(hostRef.current, isMobile);

    const goToNewSniffpass = () => {
        window.location = RouteFormatter.subscriptionsNew(null, { query: { src: memberSrc } });
    };

    const goToMemberPage = () => {
        window.location = RouteFormatter.membershipsDetail({ id: spot.id }, { query: { src: memberSrc } });
    };

    const moreHandler = () => {
        const re = `${window.location.pathname}#${AUTH_TESTS.MORE_REVIEWS}`;
        CookieService.default.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.SPOT_DETAILS_WEB, { expires: 1 });
        CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
        window.location = RouteFormatter.signUp();
    };

    const handleCloseCal = () => {
        window.location.hash = '';
        setShowCalendar('');
    };

    const closeScrollModal = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
        setTimeout(() => setOpenScrollModal(false), 100);
    };

    const scrollTop = useCallback((e) => {
        const window = e.currentTarget;
        const winY = window.innerHeight;
        if ((isMobile && y > winY * 2) || (!isMobile && y > winY)) {
            setOpenScrollModal(true);
        }
        y = window.scrollY;
    });

    useEffect(() => {
        if (!isGuestScrollTest) {
            return;
        }

        y = window.scrollY;
        window.addEventListener('scroll', scrollTop);
        return () => {
            window.removeEventListener('scroll', scrollTop);
        };
    }, [scrollTop, isGuestScrollTest]);

    const spotSrc = ImageHelper.getUrlFromOptions(spot.spotPhotos?.length ? spot.spotPhotos[0].imageAws : defImgSpot, `fit-in/${IMG_RESIZES.x0354}`);

    if (mobilePhotoGallery) {
        return (
            <RenderMobilePhotoGallery
                {...{
                    spot,
                    singleViewPhoto,
                    singlePhotoHandler,
                    setMobilePhotoCount,
                    setMobilePhotoGallery,
                    setSingleViewPhoto,
                    mobilePhotoCount,
                    handleBook,
                    handleSwipeMobile,
                }}
            />
        );
    }

    return (
        <>
            <SeoLocalBusiness spot={spot} withReviews={spot.reviews} />
            {isSpotTest && <RenderSpotNext {...{ topBarData, canPrev, isTopBarSignup }} />}
            <div className="spot-view-page container">
                <div className="spot-detail-block">
                    <div className="head-container">
                        <div className="d-md-none">
                            <RenderHeadMobile
                                {...{
                                    spot,
                                    setMobilePhotoGallery,
                                    handleSwipeMobile,
                                    mobilePhotoCount,
                                    listBread,
                                    goToReviews,
                                    goToHost,
                                    handleBook,
                                    handleMembershipBadge,
                                    handleSniffpassBadge,
                                    getTestWithProbSSR,
                                    getProbTest,
                                    mutateViewAuthSpot,
                                    isMobile,
                                    useState,
                                    useEffect,
                                    serverSSR,
                                    isHappyDogTest,
                                    mutateViewHappyDogTest,
                                }}
                            />
                        </div>
                        <div className="d-none d-md-block">
                            <RenderHeadDesktop
                                SSR
                                {...{
                                    listBread,
                                    spot,
                                    goToHost,
                                    goToReviews,
                                    toggleDesktopPhotoGallery,
                                    desktopPhotoCount,
                                    desktopPhotoGallery,
                                    handleNextDesktopGallery,
                                    handleBackDesktopGallery,
                                    handleMembershipBadge,
                                    handleSniffpassBadge,
                                    isMobile,
                                    useState,
                                    useEffect,
                                    useCallback,
                                    favSpots,
                                    useMutateFavorites,
                                    userDetails,
                                    useGetShareLink,
                                    setDesktopPhotoCount,
                                    serverSSR,
                                }}
                            />
                        </div>
                    </div>
                    <ListingFencing
                        isMobileSSR={isMobile}
                        useStateSSR={useState}
                        encls={spot.enclosureType}
                        fncHeight={spot.fenceHeight}
                        gaps={spot.gaps}
                        fencingType={spot.fencingType}
                        fencingDetail={spot.fencingDetail}
                        fencingPercentage={spot.fencingPercentage}
                    />
                    <ListingPresent
                        spotId={spot.id}
                        isMobile={isMobile}
                        userDetails={userDetails}
                        anmlsPrsnt={spot.domesticAnimalsPresent}
                        dogsPrsnt={spot.dogsPresent}
                        pplePrsnt={spot.peoplePresent}
                        dogsAway={spot.dogsAway}
                        peopleAway={spot.peopleAway}
                        prvtEntry={spot.privateEntry}
                        describePrivateEntry={spot.describePrivateEntry}
                        describeDomesticAnimalsPresent={spot.describeDomesticAnimalsPresent}
                        describeDogsPresent={spot.describeDogsPresent}
                        describePeoplePresent={spot.describePeoplePresent}
                        dogsPercentage={spot.dogsPercentage}
                        otherAnimalsPercentage={spot.otherAnimalsPercentage}
                        peoplePercentage={spot.peoplePercentage}
                        useStateSSR={useState}
                        mutateViewAuthSpot={mutateViewAuthSpot}
                        serverSSR={serverSSR}
                    />
                    <ListingAmenities
                        userDetails={userDetails}
                        isMobileSSR={isMobile}
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                        dogsAmenities={spot.dogsAmenities}
                        essentialAmenities={spot.essentialAmenities}
                        peopleAmenities={spot.peopleAmenities}
                        venueAmenities={spot.venueAmenities}
                        mutateViewAuthSpot={mutateViewAuthSpot}
                        serverSSR={serverSSR}
                    />
                    <RenderPrice SSR {...{ isMobile, spot }} />
                    <ListingLocation
                        isMobileSSR={isMobile}
                        useStateSSR={useState}
                        latitude={spot.latitude}
                        longitude={spot.longitude}
                        dscrpt={spot.description}
                        hazards={spot.hazards}
                        clean={spot.cleanlinessPercentage}
                        city={spot.city}
                        state={spot.state}
                        distanceFrom={spot.distanceFrom}
                    />
                    <ListingExtras
                        spot={spot}
                        extras={spot.extras ? [...spot.extras].sort(sortByObjTitle) : []}
                        isMobileSSR={isMobile}
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                        userDetails={userDetails}
                        mutateViewAuthSpot={mutateViewAuthSpot}
                        serverSSR={serverSSR}
                    />
                    <ListingRules
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                        isMobileSSR={isMobile}
                        breedSizeRestrictions={spot.breedSizeRestrictions}
                        hostPresencePreference={spot.hostPresencePreference}
                        reservationDelayTime={spot.reservationDelayTime}
                        maximumDogsAllowed={spot.maximumDogsAllowed}
                        minimumLength={spot.minimumLength}
                        instantBook={spot.instantBook}
                        rules={spot.rules}
                        avatar={spot.host?.avatarAws}
                    />

                    {/* RENDER CALENDAR */}
                    {showCalendar ? (
                        isMobile ? (
                            <>
                                <ListingAvailability {...{ isMobile, handleBook }} />
                                <MobileSubPage
                                    show={isMobile && !!showCalendar}
                                    className="calendar"
                                    title="Spot availability"
                                    onBack={handleCloseCal}
                                    useEffectSSR={useEffect}
                                    useStateSSR={useState}
                                >
                                    <GuestSpotCalendar
                                        {...{
                                            spotDetails: spot,
                                            isMobile,
                                            spotId: spot.id,
                                            showCalendar,
                                            useEffect,
                                            useState,
                                            usePrevious,
                                            useGetAvailableTimes,
                                            useLazyGetIntentCal,
                                            useUpdateIntent,
                                            userDetails,
                                            Select,
                                            trackEvent,
                                        }}
                                    />
                                </MobileSubPage>
                            </>
                        ) : (
                            <ModalMobile isOpen={!!showCalendar} toggle={handleCloseCal} title="Spot availability" className="spot-avail-desktop">
                                <GuestSpotCalendar
                                    {...{
                                        spotDetails: spot,
                                        isMobile,
                                        spotId: spot.id,
                                        showCalendar,
                                        useEffect,
                                        useState,
                                        usePrevious,
                                        useGetAvailableTimes,
                                        useLazyGetIntentCal,
                                        useUpdateIntent,
                                        userDetails,
                                        Select,
                                        trackEvent,
                                    }}
                                />
                            </ModalMobile>
                        )
                    ) : (
                        ''
                    )}
                    {/*  */}

                    {/* RENDER WARNING BEFORE BOOK */}
                    <RenderModalWarning
                        {...{
                            warning,
                            setWarning,
                            spotRating: spot.rating?.toPrecision(2),
                            onAskQuestion,
                            setShowCalendar,
                            useState,
                        }}
                    />
                    {/*  */}

                    <div ref={reviewsRef}>
                        <ListingReviews
                            isMobile={isMobile}
                            spot={spot}
                            host={spot.host}
                            isTop={spot.top}
                            spotId={spot.id}
                            rating={spot.rating}
                            reviews={spot.reviews}
                            changeSort={changeSort}
                            moreHandler={moreHandler}
                            sortReviews={sortReviews}
                            reviewsCount={spot.reviewsCount}
                            handleMembershipReviewBadge={handleMembershipReviewBadge}
                            useEffectSSR={useEffect}
                            useStateSSR={useState}
                            useRefSSR={useRef}
                            useAddReplySSR={useAddReply}
                            useDeleteReplySSR={useDeleteReply}
                            useHandleUpvoteSSR={useHandleUpvote}
                            useGetSpotReviewsSSR={useGetSpotReviews}
                            useOnScreenSSR={useOnScreen}
                            userDetails={userDetails}
                            dataReviews={dataReviews}
                            sortBy={sortBy}
                            mutateViewAuthSpot={mutateViewAuthSpot}
                            serverSSR={serverSSR}
                        />
                    </div>

                    <div ref={hostRef}>
                        <ListingHost
                            hostId={spot.host?.id}
                            nameAndInitial={spot.host?.nameAndInitial}
                            avatar={spot.host?.avatarAws}
                            info={spot.host?.aboutHost}
                            email={spot.host?.email}
                            joinDate={spot.host?.createdAt}
                            acceptanceRate={spot.acceptanceRate}
                            responseTime={spot.responseTime}
                            instantBook={spot.instantBook}
                            isMobile={isMobile}
                            useStateSSR={useState}
                            serverSSR={serverSSR}
                        />
                    </div>
                    <div className="ask-container mx-2 mx-md-0">
                        <div className="row ask-row">
                            <div className="col-md-12">
                                <SniffButton className="mb-3" color="secondary" size="lg" onClick={onAskQuestion}>
                                    Ask the host a question
                                </SniffButton>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none">
                        <RenderNearbySpots SSR {...{ spot, useEffect, useState, usePrevious }} />
                    </div>
                    {!showCalendar && (
                        <ReservationPanel
                            commentsNmb={spot.reviewsCount}
                            title={spot.title}
                            rating={spot.rating}
                            price={spot.price}
                            instantBook={spot.instantBook}
                            live={spot.live && spot.host?.id > 0}
                            handleBook={handleBook}
                        />
                    )}
                </div>
                <div className={`desktop-book-block${isSpotTest ? ' spot-top-bar-test' : ''}${!spot.live || spot.host?.id == 0 ? ' disabled' : ''}`}>
                    <div className="desktop-book-block__content">
                        <DesktopBookBlock {...{ spot, handleBook, useState }} />
                    </div>
                    <RenderHappyDogGuarantee isHappyDogTest={isHappyDogTest} mutateViewHappyDogTest={mutateViewHappyDogTest} />
                    <RenderSpotBadge {...{ spot }} />
                    {spot.isMember && <RenderUserIsMember SSR />}
                </div>
            </div>
            <div className="d-none d-md-block">
                <RenderNearbySpots SSR {...{ spot, useEffect, useState, usePrevious }} />
            </div>
            <div className="d-md-none spot-view-margin" />
            {spot.offerMembership && spot.activeMembership && (
                <ModalMobile isOpen={openMembership} toggle={toggleOpenMembership}>
                    <div className={b('header')}>
                        <div className={b('close')} onClick={toggleOpenMembership}>
                            <SvgCross2 />
                        </div>
                    </div>
                    <div className={b('content')}>
                        <div className={b('thumbnail')}>
                            <img src={spotSrc} alt="Spot photo" />
                            <SvgMembership />
                        </div>
                        <p className="snif-p snif-semibold mt-2">Become a member!</p>
                        <p className="snif-s1 mt-1 pt-05">Support this host and access member-only hours with a fixed monthly price.</p>
                        <p className="snif-s1 mt-3">Starting at</p>
                        <p className="snif-l2 snif-semibold">{showPrice(spot.memberPrice2Hour)}</p>
                        <p className="snif-s2 snif-medium mt-05">per dog per month</p>
                        <SniffButton className={b('cta')} size="lg" block onClick={goToMemberPage}>
                            Learn more
                        </SniffButton>
                    </div>
                </ModalMobile>
            )}
            <ModalMobile isOpen={openSniffpass} toggle={toggleOpenSniffpass}>
                <div className={bs('header')}>
                    <div className={bs('close')} onClick={toggleOpenSniffpass}>
                        <SvgCross2 />
                    </div>
                </div>
                <div className={bs('content')}>
                    <div className={bs('thumbnail')}>
                        <SvgSubscription />
                    </div>
                    <p className="snif-p snif-semibold mt-2">Become a Sniffpass holder!</p>
                    <p className="snif-s1 mt-1 pt-05 px-md-3">
                        Get discounts on all bookings at all spots by buying monthly credits! This spot offers additional discounts.
                    </p>

                    <p className="snif-s1 mt-3">Starting at</p>
                    <p className="snif-l2 snif-semibold">{showPrice(20)}</p>
                    <p className="snif-s2 snif-medium mt-05">per month</p>
                    <SniffButton className={bs('cta')} size="lg" block onClick={goToNewSniffpass}>
                        Learn more
                    </SniffButton>
                </div>
            </ModalMobile>
            <ModalMobile isOpen={openScrollModal} toggle={closeScrollModal}>
                <div className="scroll-modal-content_close" onClick={closeScrollModal}>
                    <SvgCross2 />
                </div>
                <p className="text-center mt-3 mb-3">
                    <SvgSnifLogo width={185} height={32} />
                </p>
                <p className="snif-m1 snif-semibold text-center mb-3">Sign up</p>
                <p className="snif-s1 text-medium text-center mb-3">You need to sign up or sign in before continuing</p>
                <RenderSignUpEmail
                    {...{
                        b: bSignUp,
                        signUp: true,
                        isFocused,
                        setIsFocused,
                        src: SIGN_UP_SRC.SPOT_DETAIL_SCROLL_WEB,
                        from: typeof window === 'undefined' ? '' : window.location.pathname,
                        showOptions: true,
                    }}
                />
            </ModalMobile>
        </>
    );
};

export default SpotViewPage;
