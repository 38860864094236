import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import bemCn from 'bem-cn';
import { RouteFormatter } from '../../routes';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SPOT_MIN_REQ_PHOTOS } from '../../consts/spot.consts';
import { SIGN_UP_SRC } from '../../consts/profile.const';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { PREVIEW_TYPES } from '../../consts/images.const';
import RequestMorePhotos from '../request-more-photos';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { ImageHelper } from '../../classes/image-helper.class';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { AUTH_TESTS } from '../../pages/spot-view-page/auth-test.const';
import CookieService from '../../services/cookie.service';
import SniffButton from '../sniff-button';
import PictureSet from '../picture-set';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import '@splidejs/splide/dist/css/splide.min.css';
import './index.scss';

const b = bemCn('request-more-photos');

const ArrowBtn = ({ activeIndex, length, showArrow, next, prev }) => {
    if (!showArrow || length == 1) {
        return '';
    }

    return (
        <div className="mysplide__arrows">
            <button className="mysplide__arrow mysplide__arrow--prev" style={{ visibility: activeIndex == 0 ? 'hidden' : 'visible' }} onClick={prev}>
                <SvgChevronLeft />
            </button>
            <button
                className="mysplide__arrow mysplide__arrow--next"
                style={{ visibility: activeIndex == length - 1 ? 'hidden' : 'visible' }}
                onClick={next}
            >
                <SvgChevronRight />
            </button>
        </div>
    );
};

const ReqPhotos = ({ id }) => (
    <SplideSlide>
        <div className="car-inner-photo-req">
            <RequestMorePhotos id={id} />
        </div>
    </SplideSlide>
);

const Slides = ({
    images,
    previewType,
    spotGallery,
    spotId,
    title,
    state,
    city,
    onClick,
    host,
    creatorName,
    lazy = 'lazy',
    isPhotoCarouselTest,
    publicPark,
}) => {
    const isMobile = useMobileScreen();
    const altImg = title ? `${title} - ${city}, ${state}` : 'Spot thumbnail';
    const isShowReq = !isPhotoCarouselTest && spotGallery && images.length < SPOT_MIN_REQ_PHOTOS;
    const addedOpt = host ? `fit-in/${IMG_RESIZES.x1536}` : `full-fit-in/${IMG_RESIZES.x768}`;

    const handleAllPhotos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const re = `${RouteFormatter.listings({ first: spotId })}#${isMobile ? AUTH_TESTS.MOBILE_PHOTO_GALLERY : AUTH_TESTS.DESKTOP_PHOTO_GALLERY}`;
        CookieService.set(COOKIE_PARAM_NAME.REDIRECT, re);
        CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.PHOTO_CAROUSEL_WEB);
        window.location = RouteFormatter.signUp();
    };

    const photoSlides = images.map((item, index) => {
        const methodDef = index == 0 ? lazy : 'lazy';
        const imgUrl = ImageHelper.getUrlFromOptions(item.imageAws || item, addedOpt);
        const isLastTest = isPhotoCarouselTest && index == 2;

        return (
            <SplideSlide key={item.id || index}>
                <PictureSet
                    imgs={imgUrl}
                    data-splide-lazy={imgUrl}
                    className={`img carousel-spot-inner ${previewType === PREVIEW_TYPES.FULL ? 'full-horizontal' : ''}`}
                    alt={altImg}
                    method={methodDef}
                    fetchpriority={methodDef === 'eager' ? 'high' : 'auto'}
                    width={468.76}
                    height={300}
                    noLoad
                    onClick={onClick}
                    creatorName={creatorName}
                />
                {isLastTest && (
                    <div className="img-spot-over-carousel-test">
                        <SniffButton size="lg" color="secondary" onClick={(e) => handleAllPhotos(e)}>
                            See all photos
                        </SniffButton>
                    </div>
                )}
            </SplideSlide>
        );
    });

    return isShowReq && !publicPark ? [...photoSlides, <ReqPhotos key="req" id={spotId} />] : photoSlides;
};

const SniffCarousel = ({
    images,
    onClick = () => {},
    useIndicators = true,
    disableArrow = false,
    reservationStyle,
    handleSwipeMobile,
    spotGallery,
    previewType,
    spotHost,
    spotId,
    title,
    state,
    city,
    lazy,
    host,
    singleViewPhoto,
    publicPark,
    disableSwipe,
    isPhotoCarouselTest,
}) => {
    const isMobile = useMobileScreen();
    const [activeIndex, setActiveIndex] = useState(0);
    const [splideRefNode, setSplideRefNode] = useState(null);
    const [maxLimit, setMaxLimit] = useState(singleViewPhoto ? singleViewPhoto : images.length > 1 ? 5 : images.length);
    const showArrow = disableSwipe || (!isMobile && (spotGallery || !disableArrow));
    const length = spotGallery && images.length < SPOT_MIN_REQ_PHOTOS ? images.length + 1 : images.length;
    const creatorName = `${spotHost?.nameAndInitial} | Sniffspot` || undefined;

    const splideRef = spotGallery
        ? useRef(null)
        : useCallback((node) => {
              if (singleViewPhoto >= 0) {
                  node?.splide.go(singleViewPhoto);
              }
              setSplideRefNode(node);
          }, []);

    const next = (e) => {
        e.preventDefault();
        if (splideRef.current) {
            splideRef.current.splide.go('+1');
        } else {
            splideRefNode.splide.go('+1');
        }
    };

    const prev = (e) => {
        e.preventDefault();
        if (splideRef.current) {
            splideRef.current.splide.go('-1');
        } else {
            splideRefNode.splide.go('-1');
        }
    };

    useEffect(() => {
        if (activeIndex > maxLimit - 4 && maxLimit < length) {
            setMaxLimit((limit) => (limit + 5 > length ? length : limit + 5));
        }
    }, [activeIndex]);

    if (images.length < 1) {
        if (publicPark) {
            return (
                <div className="carousel__photo-container">
                    <div className="car-inner-photo-req">
                        <div className={b()}>
                            <p className="snif-p snif-semibold text-center no-height">No available photo</p>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="carousel__photo-container">
                <div className="car-inner-photo-req">
                    <RequestMorePhotos id={spotId} />
                </div>
            </div>
        );
    }

    const myTempImages = useMemo(() => images.slice(0, maxLimit), [maxLimit]);
    const myImages = isPhotoCarouselTest ? images.slice(0, 3) : myTempImages;

    return (
        <div className={`carousel__photo-container ${lazy === 'eager' ? 'p-lcp' : ''}`}>
            <Splide
                ref={splideRef}
                options={{
                    focus: 'center',
                    pagination: false,
                    arrows: false,
                    lazyLoad: 'nearby',
                    flickMaxPages: 1,
                    drag: !disableSwipe,
                }}
                onMove={(_, idx) => {
                    setActiveIndex(idx);
                    handleSwipeMobile && handleSwipeMobile(idx);
                }}
            >
                <Slides
                    {...{
                        images: myImages,
                        previewType,
                        spotGallery,
                        spotId,
                        title,
                        state,
                        city,
                        lazy,
                        onClick,
                        host,
                        creatorName,
                        isPhotoCarouselTest,
                        publicPark,
                    }}
                />
            </Splide>
            <ArrowBtn {...{ activeIndex, length: myImages.length, showArrow, next, prev }} />
            {useIndicators && !reservationStyle && (
                <div className="spot-detail-photos-counter">
                    <div className="photos-text">
                        {activeIndex + 1} of {length}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SniffCarousel;
